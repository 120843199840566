<template>   
    <v-dialog v-model="dialog" :fullscreen="isMobile" width="800px" scrollable eager 
              @click:outside="closeDialog">
        
        <v-card tile class="filter-card" max-height="700px">

            <v-card-title class="secondary white--text">
                <span class="text-h5">Additional Help</span>
            </v-card-title>

            <v-card-text class="pa-0">
                <v-container>
                    <div class="ma-2 filter-text help_dialog">

                        <div class="mb-5">Below are answers to the RPD Portal's most common issues. If you cannot find assistance below or would like to report an issue please contact an RPD Dev Admin to assist you via email: <a href="mailto:rpdinnotech@gmail.com ">rpdinnotech@gmail.com</a> </div>

                        <v-expansion-panels hover accordion>

                            <v-expansion-panel class="coll-panel">
                                <v-expansion-panel-header class="coll-header">
                                    What is my RPD Portal Account Username?
                                    <template v-slot:actions>
                                        <v-icon color="white">
                                            $expand
                                        </v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="coll-content" eager>
                                    Your RPD Portal account username is the same email you used to register initially.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel class="coll-panel">
                                <v-expansion-panel-header class="coll-header">
                                    How do I register for an RPD Portal account?
                                    <template v-slot:actions>
                                        <v-icon color="white">
                                            $expand
                                        </v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="coll-content" eager>
                                    You can register for an account by clicking the <strong>"NEED AN ACCOUNT?"</strong> button on the login page and following the on-screen instructions.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel class="coll-panel">
                                <v-expansion-panel-header class="coll-header">
                                    How do I reset my RPD Portal password?
                                    <template v-slot:actions>
                                        <v-icon color="white">
                                            $expand
                                        </v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="coll-content" eager>
                                    Your password can be reset using the <strong>"FORGOT YOUR PASSWORD?"</strong> button on the login page and following the on-screen instructions.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel class="coll-panel">
                                <v-expansion-panel-header class="coll-header">
                                    I tried resetting my password but I forgot my security question answer
                                    <template v-slot:actions>
                                        <v-icon color="white">
                                            $expand
                                        </v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="coll-content" eager>
                                    If you forgot the answer to your security question, please contact an RPD Dev Admin to assist you via email: <a href="mailto:rpdinnotech@gmail.com ">rpdinnotech@gmail.com</a> using the email you registered your account with.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel class="coll-panel">
                                <v-expansion-panel-header class="coll-header">
                                    How do I request access to additional RPD applications?
                                    <template v-slot:actions>
                                        <v-icon color="white">
                                            $expand
                                        </v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="coll-content" eager>
                                    To request access to additional RPD applications, please contact Officer Noah Gallop via email: <a href="mailto:gallopn@reno.gov">gallopn@reno.gov</a> with your name and the name of the applications you are requesting access to.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel class="coll-panel">
                                <v-expansion-panel-header class="coll-header">
                                    How do I clear my web browser cache?
                                    <template v-slot:actions>
                                        <v-icon color="white">
                                            $expand
                                        </v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="coll-content" eager>
                                    Clearing the cache and cookies from a web browser is an important step in troubleshooting issues. It is recommended you use Google Chrome for all RPD applications.
                                    <br /> <br />
                                    To clear your cache in Google Chrome please follow the following :
                                    <br /> 1. On your computer, open Chrome.
                                    <br /> 2. At the top right, click the three dots.
                                    <br /> 3. Then click More tools > Clear browsing data.
                                    <br /> 4. At the top, choose a time range. To delete everything, select All time.
                                    <br /> 5. Next, ensure "Cookies and other site data" and "Cached images and files," are checked
                                    <br /> 6. Finally, click Clear data.
                                    <br /> <br />
                                    Note: If you are using Internet Explorer, Edge, Google Chrome, or Mozilla Firefox on a desktop you can quickly clear your cache with a keyboard shortcut. While in your browser, press <strong>Ctrl + Shift + Delete simultaneously</strong> on the keyboard to open the appropriate window. For other browsers, you may need to search for how to clear their cache.

                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel class="coll-panel">
                                <v-expansion-panel-header class="coll-header">
                                    When I was registering I got an error that prevented me from finishing
                                    <template v-slot:actions>
                                        <v-icon color="white">
                                            $expand
                                        </v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="coll-content" eager>
                                    Depending on how long and what step you were in during the registration process you likely have to start all over. Please refresh the webpage and restart the process.
                                    <br /> <br />
                                    If you continue to encounter the same issue please clear your web browser cache (<strong>see the web browser cache question for instructions</strong>) or try a different device and try again.
                                    <br /> <br />
                                    If none of the above has worked, an additional option may be to open an Incognito Tab in your Google Chrome browser and attempt logging in.
                                    <br /> <br />
                                    If the same issue continues to occur please contact an RPD Dev Admin to assist you via email: <a href="mailto:rpdinnotech@gmail.com ">rpdinnotech@gmail.com</a>

                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel class="coll-panel">
                                <v-expansion-panel-header class="coll-header">
                                    When I tried logging in I got an error that prevented me even though my email/password was correct
                                    <template v-slot:actions>
                                        <v-icon color="white">
                                            $expand
                                        </v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="coll-content" eager>
                                    Depending on how long it has been since you last used the RPD Portal your session may have expired. Please refresh the webpage and try again.
                                    <br /> <br />
                                    If you continue to encounter the same issue please clear your web browser cache (<strong>see the web browser cache question for instructions</strong>) or try a different device and try again.
                                    <br /> <br />
                                    If none of the above has worked, an additional option may be to open an Incognito Tab in your Google Chrome browser and attempt logging in.
                                    <br /> <br />
                                    If the same issue continues to occur please contact an RPD Dev Admin to assist you via email: <a href="mailto:rpdinnotech@gmail.com ">rpdinnotech@gmail.com</a>

                                </v-expansion-panel-content>
                            </v-expansion-panel>

                        </v-expansion-panels>

                    </div>


                </v-container>
            </v-card-text>


            <v-card-actions style="align-self: center;">
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="closeDialog" text class="pa-2 ma-2 text-center">Close</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>

        </v-card>

    </v-dialog>

  


</template>

<style lang="scss" scoped>


    .help_dialog .v-expansion-panel, .v-expansion-panel-header, .v-expansion-panel-content {
        transition: none !important;
    }
</style>

<script>
    /* eslint-disable */
    export default {
        name: "helpdialog",
        data() {
            return {
                isMobile: this.$vuetify.breakpoint.mobile,
                dialog: true
            };
        },
        methods: {
            closeDialog() {
                this.dialog = false;
                setTimeout(() => {
                    this.$emit('closeDialog');
                }, 300)
            },
            applyFilters() {
                this.$emit("closeDialog");
            },
        },
    };
</script>
